<template>
  <b-modal
    modal-class="component-modal-caries-risk-assesment"
    ref="CariesRisk"
    :lazy="true"
    size="fluid"
    header-class="py-2"
    title="ประเมินความเสียงฟันผุ"
    no-close-on-backdrop
    no-close-on-esc
    scrollable
    :ok-title="isAnswer ? 'แก้ไข' : 'บันทึก'"
    :ok-variant="isAnswer ? 'warning' : 'primary'"
    ok-only
    @ok="createRiskAssesment"
    @hidden="defaultValue"
  >
    <b-table-simple small responsive>
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>ปัจจัยสาเหตุ</b-th>
          <b-th
            ><b-row
              ><b-col cols="4"></b-col><b-col cols="4"></b-col
              ><b-col cols="4" class="px-0"></b-col></b-row
          ></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="q in questions"
          :key="q.id"
          :variant="q.type === 'header' ? 'secondary' : ''"
        >
          <b-th v-if="q.type === 'header'" colspan="2">
            {{ q.text }}
          </b-th>

          <b-td v-else>{{ q.text }} </b-td>
          <b-td>
            <b-form-radio-group v-model="answers[q.id]"
              ><b-row
                ><b-col cols="4" v-for="(value, key) in q.answer" :key="key">
                  <b-form-radio :value="key">{{ value }}</b-form-radio>
                </b-col></b-row
              ></b-form-radio-group
            ></b-td
          >
        </b-tr>
      </b-tbody>
      <b-tfoot foot-variant="light">
        <b-tr>
          <b-th>สรุปผลการประเมิน: มีความเสี่ยงโรคฟันผุอยู่ในระดับ</b-th>
          <b-th v-if="evaluationScore === 'high'" class="text-danger">สูง</b-th>
          <b-th v-else-if="evaluationScore === 'medium'" class="text-warning"
            >ปานกลาง</b-th
          >
          <b-th v-else-if="evaluationScore === 'low'" class="text-success"
            >ต่ำ</b-th
          >
          <b-th v-else class="">N/A</b-th>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Loading from "@/components/Loading";
// import PatientProfile from "@/components/PatientProfile";
import Dialog from "@/components/modal/Dialog";
import { required, requiredIf } from "vuelidate/lib/validators";

import { eventBus } from "../../main";

export default {
  name: "CariesRisk",
  components: {
    // PatientProfile,
    Loading,
    Dialog,
  },
  props: {},
  validations: {},

  data() {
    return {
      questions: [],
      answers: {},
      patientId: null,
      riskQuestionsId: null,
      answersId: null,

      isLoading: false,
    };
  },

  created() {
    this.init();
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapGetters({}),
    evaluationScore() {
      if (Object.keys(this.answers).length < 13) return "N/A";
      if (Object.values(this.answers).indexOf("high") > -1) return "high";
      if (Object.values(this.answers).indexOf("medium") > -1) return "medium";
      return "low";
    },
    isAnswer() {
      return this.answersId > 0;
    },
  },
  methods: {
    ...mapActions({
      fetchQuestions: "moduleCariesRiskAssessment/fetchQuestions",
      fetchAnswers: "moduleCariesRiskAssessment/fetchAnswers",
      createAnswers: "moduleCariesRiskAssessment/createAnswers",
      updateAnswers: "moduleCariesRiskAssessment/updateAnswers",
    }),
    init() {},
    async show(patientId) {
      try {
        this.isLoading = true;
        this.patientId = patientId;
        const res = await this.fetchAnswers({
          patientID: this.patientId,
        });
        const answers = res.data;
        this.answersId = answers?.id;
        if (answers?.id) {
          this.questions = answers.questions;
          this.riskQuestionsId = answers.riskQuestionsId;
          this.answers = this.convertAnswerToObj(answers.answers);
          return;
        } else {
          const { id, questions } = await this.fetchQuestions({
            id: this.answers?.riskQuestionsId,
          });
          this.riskQuestionsId = id;
          this.questions = questions;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
        await this.$nextTick();
        this.$refs["CariesRisk"].show();
      }
    },
    convertAnswerToObj(array) {
      return array.reduce(
        (obj, item) => Object.assign(obj, { [item.questionId]: item.value }),
        {}
      );
    },
    async createRiskAssesment(bvModalEvent) {
      bvModalEvent.preventDefault();
      if (Object.keys(this.answers).length < 13) {
        this.$refs.Dialog.showAlertInfo(
          "ตอบคำถามไม่ครบ",
          "กรุณาตอบให้ครบทุกคำถาม",
          "warning"
        );
        return;
      }
      const res = await this.$refs.Dialog.showAlertConfirm(
        `ประเมินความเสี่ยง "${this.evaluationText()}"`,
        "คุณต้องการบันทึกประเมินความเสี่ยง ?"
      );
      if (res.isConfirmed) {
        try {
          this.isLoading = true;
          let data = this.formatAnswers();
          if (this.isAnswer) {
            const res = await this.updateAnswers({ data });
            eventBus.$emit("alertToast", {
              message: "แก้ไขประเมินความเสี่ยงฟันผุสำเร็จ",
              variant: "success",
            });
          } else {
            const res = await this.createAnswers({ data });
            eventBus.$emit("alertToast", {
              message: "บันทึกประเมินความเสี่ยงฟันผุสำเร็จ",
              variant: "success",
            });
          }
        } catch (err) {
          console.error(err);
        } finally {
          this.isLoading = false;
          await this.$nextTick();
          this.$refs["CariesRisk"].hide();
        }
      }
    },

    formatAnswers() {
      let formattedAnswers = [];
      for (let [id, value] of Object.entries(this.answers)) {
        formattedAnswers.push({
          questionId: Number(id),
          value,
        });
      }
      formattedAnswers.sort((a, b) => a.questionId - b.questionId);
      return {
        id: this.answersId,
        patientId: this.patientId,
        riskQuestionsId: this.riskQuestionsId,
        evaluationScore: this.formatEvaluationScore(),
        answers: formattedAnswers,
      };
    },
    formatEvaluationScore() {
      switch (this.evaluationScore) {
        case "low":
          return 1;
        case "medium":
          return 2;
        case "high":
          return 3;
      }
    },
    evaluationText() {
      switch (this.evaluationScore) {
        case "low":
          return "ต่ำ";
        case "medium":
          return "กลาง";
        case "high":
          return "สูง";
      }
    },
    defaultValue() {
      this.answers = [];
      this.questions = [];
      this.patientId = null;
      this.riskQuestionsId = null;
      this.answersId = null;
    },
  },
};
</script>

<style>
</style>